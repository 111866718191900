import axios from 'axios';
const BASE_URL = 'https://nottyend.vercel.app';
//const BASE_URL = 'http://localhost:3000';

export default axios.create({
  baseURL: BASE_URL,
});
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});
