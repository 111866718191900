// feed.jsx

import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import Card from '../../components/Card/Card';

const Feed = () => {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await axios.get(`/api/v1/users`);
        const escorts = response?.data?.data.users;

        setUserData(escorts);
      };
      fetchData();
    } catch (error) {
      //console.log('Error: ', error.message);
    }

    //fetchData();
  }, []);

  return (
    <div className='feed'>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
        {userData.map(user => (
          <Card key={user._id} user={user} />
        ))}
      </div>
    </div>
  );
};

export default Feed;
