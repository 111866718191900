import { Search, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import Card from '../../components/Card/Card';

function SearchPage() {
  const params = new URLSearchParams(document.location.search);
  const initSearchTerm = params.get('q');
  const initDisplayName = getSearchDisplay(initSearchTerm);

  const navigate = useNavigate();

  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState(initSearchTerm ?? '');
  const [isLoading, setLoading] = useState(false);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    navigate('/');
  };

  const [users, setUsers] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/search?q=${searchTerm}`);
      const users = response?.data?.data.users;

      //await new Promise(resolve => setTimeout(resolve, 1000));
      setUsers(users);
    } catch (error) {
      console.log('Error: ', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleSearch(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      searchEscorts();
    }
  }

  function searchEscorts() {
    setUsers([]);
    fetchData();
  }

  function getSearchDisplay(initSearch) {
    if (initSearch === 'top_girls') return 'Top Girls';
    if (initSearch === 'top_males') return 'Top Males';
    if (initSearch === 'is_mama_fua') return 'Mama Fuas';
    if (initSearch === 'spas') return 'SPAs';
    else return '';
  }

  return (
    <>
      {isSearchOpen && (
        <div className='fixed top-0 left-0 bottom-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-20 md:px-6 md:pt-5 px-2 pt-2 '>
          <div className='bg-white rounded-t-lg w-full h-full p-4 md:p-8 space-y-2 overflow-y-scroll'>
            <div className='flex justify-between'>
              {!initSearchTerm ? (
                <div
                  className={`rounded-lg max-w-md w-full flex  items-center justify-between gap-2 border-2 overflow-hidden ${
                    isSearchFocused ? 'border-[#8f43ec]' : 'border-gray-200'
                  }`}
                >
                  <input
                    type='text'
                    placeholder={'Enter your search keyword...'}
                    className='border-transparent focus:border-transparent focus:ring-0 pl-2 py-1 w-full'
                    onKeyDown={handleSearch}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onBlur={() => setIsSearchFocused(false)}
                    onFocus={() => setIsSearchFocused(true)}
                  />
                  <button className='p-2 bg-[#e3d1fa]' onClick={searchEscorts}>
                    <Search className='h-5 w-5 text-[#8f43ec]' />
                  </button>
                </div>
              ) : (
                <div className=' text-xl text-[#8f43ec]'>
                  List of {initDisplayName} found
                </div>
              )}
              <button
                onClick={toggleSearch}
                className=' flex gap-2 text-white  bg-[#ff3190] rounded-lg px-3 items-center justify-center ml-2 p-2'
              >
                <p>Close</p>
                <X className='hidden md:block ' />
              </button>
            </div>
            <div>
              {!initSearchTerm && (
                <p className='text-sm text-gray-500'>
                  Refine your search by escort name, location, gender,
                  orientation, services, and description
                </p>
              )}
            </div>
            <div className='py-2'>
              {!isLoading && !initSearchTerm && (
                <h4 className='text-gray-500'>
                  {users.length > 0
                    ? `Found ${users.length} escorts`
                    : 'No results found.'}
                </h4>
              )}
              {users.length > 0 && (
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 '>
                  {users.map(user => (
                    <Card key={user._id} user={user} />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='absolute left-cal(1/2 - 50px) top-1/2 z-50'>
            {isLoading && (
              <InfinitySpin
                visible={true}
                width='200'
                color='#ff3190'
                ariaLabel='infinity-spin-loading'
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default SearchPage;
