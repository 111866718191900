import { useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useRefreshToken from '../../hooks/useRefreshToken';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  // useEffect(() => {
  //   console.log(`isLoading: ${isLoading}`);
  //   console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
  // }, [isLoading, auth?.accessToken]);

  return (
    <>
      {!persist ? (
        <Outlet />
      ) : isLoading ? (
        <InfinitySpin
          visible={true}
          width='200'
          color='#ff3190'
          ariaLabel='infinity-spin-loading'
        />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
