import React from 'react';
import { Link } from 'react-router-dom';
import male from '../../assets/male.svg';
import moon from '../../assets/moon.svg';
import rainbow from '../../assets/rainbow.svg';
import sparkle from '../../assets/sparkle.svg';
import Feed from '../../components/feed/Feed';
import './Home.css';

const Home = () => {
  return (
    <div className='xl:max-w-[85%]  mx-auto'>
      {/* Visual Advertisements */}
      {/* <div className="heroads">
        <div className="leftad">
          <img src="assets/leftad.gif" alt="..." />
        </div>
        <div className="heroad ">
          <Carousel>
            <img src="assets/heroad1.gif" alt="..." />
          </Carousel>
        </div>

        <div className="rightad">
          <img src="assets/leftad.gif" alt="..." />
        </div>
      </div> */}

      <div className='flex flex-col md:flex-row gap-2 max-h-[20%] p-4 lg:py-8 md:p-4 justify-center'>
        <div className=' md:w-[22%] xl:w-[25%] hidden md:block rounded-lg overflow-hidden'>
          <video
            muted={true}
            loop={true}
            playsInline={true}
            autoPlay={true}
            src='assets/left_hero.mp4'
            alt='Advertisement video'
            className='h-[100%]'
          />
        </div>

        <div className='w-full md:w-[56%] xl:w-[56%] rounded-lg overflow-hidden'>
          <video
            muted={true}
            loop={true}
            playsInline={true}
            autoPlay={true}
            src='assets/hero.mp4'
            alt='Advertisement video'
            className='h-[100%]'
          />
        </div>

        <div className='md:w-[22%] xl:w-[25%]  hidden md:block rounded-lg overflow-hidden'>
          <img
            src='assets/right_hero.jpg'
            alt='Right escort adverstisement'
            className='h-[100%]'
          />
        </div>
      </div>
      {/* Filter shortcut links */}

      <div className='grid gap-4 grid-cols-2 lg:grid-cols-4 m-4'>
        <Link to={`/search?q=top_girls`}>
          <div
            className={`flex gap-2 bg-white rounded-lg px-4 py-2 justify-center items-center `}
          >
            <img src={moon} alt='..' className='h-8 w-8 ' />
            Hot Girls
          </div>
        </Link>
        <Link to={`/search?q=spas`}>
          <div className='flex gap-2 bg-white rounded-lg px-4 py-2 justify-center items-center'>
            <img src={rainbow} alt='..' className='h-8 w-8 ' />
            SPAs
          </div>
        </Link>
        <Link to={`/search?q=is_mama_fua`}>
          <div className='flex gap-2 bg-white rounded-lg px-4 py-2 justify-center items-center'>
            <img src={sparkle} alt='..' className='h-8 w-8' />
            Mama Fua
          </div>
        </Link>
        <Link to={`/search?q=top_males`}>
          <div className='flex gap-2 bg-white rounded-lg px-4 py-2 justify-center items-center'>
            <img src={male} alt='..' className='h-8 w-8' />
            Top Males
          </div>
        </Link>
      </div>

      {/* Starting Feed grid */}
      <div className='feed'>
        <h1>Trending Escorts</h1>
        <Feed />
      </div>
    </div>
  );
};

export default Home;
