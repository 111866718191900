import React from 'react';

function Blog() {
  return (
    <div className='w-full h-screen flex items-center justify-center'>
      <h3 className='mx-auto mt-200px'>Blog is coming soon...</h3>
    </div>
  );
}

export default Blog;
