import React, { useEffect } from 'react';
import './Login.css'; // You can style the component in a separate CSS file

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useLocation } from 'react-router-dom';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const LoginModal = () => {
  //added
  const { setAuth, persist, setPersist } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const schema = z.object({
    email: z
      .string({
        required_error: 'Email is required',
        invalid_type_error: 'Invalid email format',
      })
      .email(),
    password: z
      .string({
        required_error: 'Password is required',
      })
      .min(8, { message: 'Password should be at least 8 characters' }),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: zodResolver(schema) });
  //

  const onSubmit = async data => {
    //console.log('Data: ', data);

    //const LOGIN_ENDPOINT = '/api/v1/auth/login';
    try {
      const response = await axios.post(
        `/api/v1/auth/login`,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      const loginData = response?.data;

      if (loginData.status !== 'success') {
        setError('root', {
          message: loginData.message,
        });
        return;
      }
      // get user if authenticated
      //console.log('USER data: ', loginData);
      const user = loginData.data.user;

      //save authdetails
      const accessToken = response?.data?.accessToken;
      setAuth({ user, accessToken });
      // take user to profile page
      navigate(from, { replace: true });

      //console.log('User Logged in: ', user);
    } catch (error) {
      setError('root', {
        message: error.message,
      });
      //console.log('error');
    }
    //login user
  };

  // toggle persit based on checkbox state
  const togglePersist = () => {
    setPersist(prev => !prev);
  };
  // save persist value to localstorage
  useEffect(() => {
    localStorage.setItem('persist', persist);
  }, [persist]);

  return (
    <div className='login-modal'>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
        <h2>Login</h2>
        <label>
          <p className='py-2'>Email</p>
          <input
            className='field'
            type='email'
            placeholder='i.e pretty@gmail.com'
            {...register('email')}
          />
        </label>
        {errors.email && <ErrorMessage message={errors.email.message} />}

        <label>
          <p className='py-2'>Password</p>
          <input
            className='field'
            type='password'
            placeholder='Password'
            {...register('password')}
          />
        </label>
        {errors.password && <ErrorMessage message={errors.password.message} />}

        <div className='space-x-2  items-center'>
          <input
            type='checkbox'
            id='persist'
            className='accent-[#ff3190] rounded-sm checked focus:accent-pink-500'
            onChange={togglePersist}
            checked={persist}
          />
          <label htmlFor='persist'>Remember me</label>
        </div>

        <button
          disabled={isSubmitting}
          className='bg-[#ff3190] px-5 py-2 text-white rounded-full hover:text-[#ff3190] hover:bg-[#ffe6f1] hover:cursor-pointer mx-2'
          type='submit'
        >
          {isSubmitting ? 'Authenticating...' : 'Login'}
        </button>
        {errors.root && <ErrorMessage message={errors.root.message} />}
      </form>
    </div>
  );
};

export function ErrorMessage({ message }) {
  return (
    <div className='w-auto text-red-600 text-md py-3'>
      <p>{message}</p>
    </div>
  );
}
export default LoginModal;
