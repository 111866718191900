import { Footer } from 'flowbite-react';
import React from 'react';
//import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footerr = () => {
  return (
    <Footer container>
      <Footer.Copyright href='#' by='nottystreet' year={2024} />
      <div className='flex gap-4 text-gray-500 flex-wrap'>
        {/* <Footer.Link href="#">About</Footer.Link> */}
        <Link to='/'>Home</Link>
        <Link to='/privacy-policy'>Privacy Policy</Link>
        <Link to='/terms-and-conditions'>Terms & Conditions</Link>
        <Link to='/blog'>Blog</Link>
        <Link to='/contact'>Contact</Link>
      </div>
    </Footer>
  );
};

export default Footerr;
