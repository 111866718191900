import { Badge } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Feed from '../../components/feed/Feed';
import './single.css';

import { ChevronRight, Home, Phone } from 'lucide-react';
import axios from '../../api/axios';

function Single() {
  let { id } = useParams();

  const [user, setUser] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    try {
      const fetchData = async () => {
        const response = await axios.get(`/api/v1/users/${id}`);
        const escort = response?.data?.data.user;

        //await new Promise(resolve => setTimeout(resolve, 1000));
        setUser(escort);
      };
      fetchData();
      // scroll to top after changing user_id
      window.scrollTo({ top: 0, behaviour: 'smooth' });
    } catch (error) {
      console.log('Error: ', error.message);
    } finally {
      setLoading(false);
    }
  }, [id]); //onclick, new id is triggered, so trigger fetching new data

  return (
    <div className='mt-3 w-full h-full'>
      <div className='bcrumbs'>
        {/*Breadcrumbs */}
        <nav className='flex' aria-label='Breadcrumb'>
          <ol className='inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse'>
            <li className='inline-flex items-center'>
              <a
                href='#'
                className='inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white'
              >
                <Home className='h-4 w-4 m-1' />
                Home
              </a>
            </li>
            <li>
              <div className='flex items-center'>
                <ChevronRight className='h-4 w-4 m-1' />
                <a
                  href='#'
                  className='ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white'
                >
                  Escorts
                </a>
              </div>
            </li>
            <li aria-current='page'>
              <div className='flex items-center'>
                <ChevronRight className='h-4 w-4 m-1' />
                <span className='ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400'>
                  {user.name && user.name.split(' ')[0]}
                </span>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className='flex gap-2 mt-6 flex-col lg:flex-row p-4'>
        <div className='mx-2 lg:mx-4 overflow-hidden rounded-xl bg-white flex-1'>
          {!isLoading && (
            <>
              <div className='flex flex-col justify-center md:justify-start gap-4 mdflex-col lg:flex-row'>
                <img
                  className=' h-[40%] max-h-[360px] w-screen md:max-w-[360px] rounded-br-xl lg:h-360 object-cover self-start'
                  src={user.photo}
                  alt='Escort photo'
                />
                <div className='p-4'>
                  <h2 className='mb-2 text-xl font-bold text-indigo-700'>
                    {user.name}
                  </h2>
                  <div className='space-y-4 font-semibold'>
                    <div className='flex gap-2'>
                      Age:
                      <p className='font-normal text-gray-700'>{user.age}</p>
                    </div>
                    <div className='flex gap-2'>
                      Gender:
                      <p className='font-normal text-gray-700'>{user.gender}</p>
                    </div>
                    <div className='flex gap-2'>
                      Location:
                      <p className='font-normal text-gray-700'>
                        {user.location}
                      </p>
                    </div>
                    <div className='flex gap-2'>
                      Orientation:
                      <p className='font-normal text-gray-700'>
                        {user.orientation}
                      </p>
                    </div>
                    <div className='flex gap-2'>
                      Type:
                      <p className='font-normal text-gray-700'>
                        {user.user_type}
                      </p>
                    </div>
                    <div className='flex gap-2 items-center'>
                      Mama Fua:
                      <Badge color='indigo' className='text-md px-5 py-1'>
                        {user.is_mama_fua ? 'Yes' : 'No'}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
              <div className='space-y-3 p-5'>
                <h3 className='font-semibold'>Description</h3>
                <p className='font-normal text-gray-700 w-fit'>
                  {user.description}
                </p>
                <h3 className='font-semibold'>Services</h3>
                <div className='flex flex-wrap gap-2'>
                  {user.services &&
                    user.services.map((service, index) => (
                      <div
                        key={index}
                        className='w-fit rounded-full bg-[#ffcce4] px-3 py-1 text-[#b30053]'
                      >
                        {service}
                      </div>
                    ))}
                </div>
                <p>For rates and more information;</p>
                <div className='text-md flex w-fit gap-2 rounded-full bg-indigo-700 px-4 py-2 text-white'>
                  <div>
                    <Phone className='h-4 w-4 m-1' />
                  </div>
                  <p>{user.phone}</p>
                </div>
              </div>
              )
            </>
          )}
        </div>

        <div className='shrink-0 md:max-w-[300px]'>
          <div className=' object-cover rounded-lg'>
            <img
              className='object-cover rounded-lg'
              src='https://www.nairobihot.com/wp-content/uploads/2022/03/kutombana-spa-ngong-road.jpg'
              alt='Escort advertisement'
            />
          </div>
        </div>
      </div>
      <div className='relatedUsers p-4 '>
        <h3>Related Escorts</h3>
        <Feed />
      </div>

      <div className='absolute left-cal(1/2 - 50px) top-1/2 z-50'>
        {isLoading && (
          <InfinitySpin
            visible={true}
            width='200'
            color='#ff3190'
            ariaLabel='infinity-spin-loading'
          />
        )}
      </div>
    </div>
  );
}

export default Single;
