import React from 'react';
import { FiMail, FiSend } from 'react-icons/fi'; // Assuming you have the react-icons library installed
import './Contact.css'; // You can style the component in a separate CSS file

const ContactUs = () => {
  return (
    <div className='flex flex-col items-center gap-5 mx-auto'>
      <h1 className='m-5 text-xl'>Contact Us</h1>

      <div className='flex flex-col gap-4'>
        {/* Join Telegram Channel Card */}
        <div className='flex flex-col md:flex-row items-center space-x-2 md:space-x-4 space-y-4 bg-white p-4 md:p-10 rounded-lg'>
          <div className='shrink-0'>
            <div className='bg-[#e3d1fa] p-2 flex items-center justify-center rounded-full'>
              <FiSend className='text-[#8f42ec] h-5 w-5' />
            </div>
          </div>
          <div className='flex-1'>
            <p className='text-lg font-medium text-gray-900 dark:text-white'>
              Join Telegram Channel
            </p>
            <p className='text-md text-gray-500 dark:text-gray-400'>
              Stay updated with our latest news and announcements on Telegram.
            </p>
          </div>
          <div>
            <a
              className='px-4 py-2 bg-[#8f42ec] text-white rounded-full'
              href='https://t.me/+LxkkS6X4d89kMjJk'
              target='_blank'
              rel='noopener noreferrer'
            >
              Join Now
            </a>
          </div>
        </div>

        <div className='flex flex-col md:flex-row items-center space-x-4 space-y-4 bg-white p-6 md:p-10 rounded-lg w'>
          <div className='shrink-0'>
            <div className='bg-[#e3d1fa] p-2 flex items-center justify-center rounded-full'>
              <FiMail className='text-[#8f42ec] h-5 w-5' />
            </div>
          </div>
          <div className='flex-1'>
            <p className='text-lg font-medium text-gray-900 dark:text-white'>
              Email Us
            </p>
            <p className='text-md text-gray-500 dark:text-gray-400'>
              For inquiries and assistance, feel free to email us at
              infonottystreet@gmail.com
            </p>
          </div>
          <div>
            <a
              className='px-4 py-2 bg-[#8f42ec] text-white rounded-full'
              href='mailto:infonottystreet@gmail.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              Send Email
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
