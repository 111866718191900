import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const UserCard = ({ user }) => {
  // default values for properties or use optional chaining
  //const { imgSrc = '', location = '', name = '', phoneNumber = '', age = '' } = user;
  //const servicesDescription = `Meet ${name}, a hot escort from ${location}. Contact for details`;

  const handleCallButtonClick = () => {
    // Using window.location.href to initiate the phone call
    window.location.href = `tel:${user.phone}`;
  };

  return (
    <Link to={`/escorts/${user._id}`}>
      <div className='user-card'>
        {/* User Image */}
        <img
          src={user.photo}
          alt={`User ${user.name}`}
          className='user-image'
        />

        {/* User Information */}
        <div className='user-info'>
          <div className='location-n-age flex justify-between'>
            <div className='location'>
              <img src='/location.svg' alt='..' />
              <p className='user-location line-clamp-1'>{user.location}</p>
            </div>
            <p className='font-semibold text-[#ff3190]'>{user.age} yrs</p>
          </div>
          <div className='username'>
            <img src='/user.svg' alt='..' />
            <h3 className='user-name line-clamp-1'>{user.name}</h3>
          </div>

          <div className='phone'>
            <img src='/call.svg' alt='..' />
            <p className='user-phone'>{user.phone}</p>
          </div>
          <p className='line-clamp-2 my-3'>{user.description}</p>
          <button className='call-button' onClick={handleCallButtonClick}>
            {/* return the first name only */}
            {/* Call {user.name.split(' ')[0]} */}
            Call {user.name && user.name.split(' ')[0]}
          </button>
        </div>

        {/* Call Button */}
      </div>
    </Link>
  );
};

export default UserCard;
