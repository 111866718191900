// feed.jsx

import React, { useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import axios from '../../api/axios';
import Card from '../../components/Card/Card';

const SpaPage = () => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/search?q=spas`);
      const users = response?.data?.data.users;

      setUserData(users);
    } catch (error) {
      console.log('Error: ', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=' py-2 p-6 w-full h-full'>
      <p className='text-gray-500 py-4'>
        Get massage from top SPAs and Parlors
      </p>
      {isLoading ? (
        <div className='mx-auto top-1/2'>
          <InfinitySpin
            visible={true}
            width='200'
            color='#ff3190'
            ariaLabel='infinity-spin-loading'
          />
        </div>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
          {userData.map(user => (
            <Card key={user._id} user={user} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SpaPage;
