import axios from '../api/axios';
import useAuth from './useAuth';

function useRefreshToken() {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get('/api/v1/auth/refresh', {
      withCredentials: true,
    });
    const data = response?.data;
    const accessToken = data?.accessToken;
    const user = data?.data?.user;

    setAuth(prevState => {
      //console.log('Prev', JSON.stringify(prevState));
      //console.log('New Access TOKEN: ', accessToken);
      return { ...prevState, accessToken, user };
    });
    return accessToken;
  };
  return refresh;
}

export default useRefreshToken;
