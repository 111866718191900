// src/App.jsx
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Content from './components/Content/Content';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import PersistLogin from './components/PersistLogin/PersistLogin';
import RequireAuth from './components/RequireAuth/RequireAuth';
import ContactUs from './pages/ContactUs/Contact';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import SpaPage from './pages/SPA/SpaPage';
import SearchPage from './pages/Search/SearchPage';
import Blog from './pages/blog/Blog';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Profile from './pages/profile/Profile';
import Single from './pages/single/Single';
import TermsConditions from './pages/terms-conditions/TermsConditions';

// import Card from './components/Card/Card';

function App() {
  return (
    <Router>
      <div className='flex flex-col min-h-screen'>
        <Header />
        <Content>
          <Routes>
            {/* Persit every route to prevent logout */}
            <Route element={<PersistLogin />}>
              <Route path='/' element={<Home />} />
              <Route path='/spas' element={<SpaPage />} />
              {/* <Route path='/shop' element={<Shop />} /> */}
              <Route path='/login' element={<Login />} />
              {/* <Route path='/adduser' element={<AddUser />} /> */}
              {/* <Route path='/uploadproduct' element={<UploadProduct />} /> */}
              <Route path='/contact' element={<ContactUs />} />
              <Route path='/escorts/:id' element={<Single />} />
              <Route element={<RequireAuth />}>
                <Route path='/profile' element={<Profile />} />
              </Route>
              <Route path='/search' element={<SearchPage />} />
              <Route path='/blog' element={<Blog />} />
              <Route
                path='/terms-and-conditions'
                element={<TermsConditions />}
              />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            </Route>
          </Routes>
        </Content>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
