import { Modal } from 'flowbite-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '../Login/Login';
import './profile.css';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Check, Trash } from 'lucide-react';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const genders = ['female', 'male', 'trans'];
const orientations = ['straight', 'lesbian', 'gay', 'trans', 'bisexual'];
//const userTypes=["individual", "parlor"]

function Profile() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useAuth();

  const stateUser = auth?.user;
  //console.log('Auth: ', auth);
  const services = stateUser.services.toString();
  stateUser.services = services;

  const [user, setUser] = useState({ ...stateUser });
  const [isEditing, setEditing] = useState(false);

  //Cloudinary image
  const [photo, setPhoto] = useState('');
  const [imageUrl, setImageUrl] = useState(user.photo);
  const [success, setSuccess] = useState('');

  const schema = z.object({
    photo: z.string({ required_error: 'Please select a photo' }),
    name: z.string().min(3, { message: 'Name must be at least 3 characters' }),
    email: z.string().email({ message: 'Enter a valid email address' }),
    phone: z.string().min(10, { message: 'Phone must be 10 numbers' }),
    age: z.coerce
      .number()
      .max(100, { message: 'Age must be less than 100' })
      .min(18, { message: 'Age must be more than 18' }),

    gender: z.string().min(1, { message: 'Please select a gender' }),
    orientation: z.string().min(1, { message: 'Please select a orientation' }),
    description: z
      .string()
      .min(3, { message: 'User description must be at least 3 characters' }),
    location: z
      .string()
      .min(3, { message: 'Location must be at least 3 characters' }),
    services: z
      .string()
      .min(3, { message: 'Services should contain at least 3 characters' }),
    // .array()
    // .nonempty()
    // .min(1, { message: 'Enter at least on service' })
    //.max(10, { message: '10 services are maximum' })

    is_mama_fua: z.boolean(),
  });

  const defaultValues = user
    ? {
        photo: user.photo,
        name: user.name,
        email: user.email,
        phone: user.phone,
        age: user.age,
        gender: user.gender,
        is_mama_fua: user.is_mama_fua,
        orientation: user.orientation,
        location: user.location,
        description: user.description,
        services: user.services.toString(),
      }
    : {
        photo: '',
        name: '',
        email: '',
        phone: '',
        age: '',
        gender: '',
        is_mama_fua: '',
        orientation: '',
        location: '',
        description: '',
        services: '',
      };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    setFocus,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: zodResolver(schema), defaultValues });

  async function updateUser(data) {
    //console.log('User Update Data b4: ', data);
    try {
      // wait the image to be uploaded to get
      const imgUrl = await uploadImage();
      //update photo
      //console.log('Photo b4: ', imgUrl);
      data.photo = imgUrl;
      //console.log('User Update Data after: ', data);

      if (!imageUrl) {
        setError('photo', { message: 'Please select a photo' });
        return;
      }

      //console.log('User: ', user);
      //console.log('Edited User: ', data);

      const editedUser = compareObjects(user, data);
      //console.log('Edited User: ', editedUser);
      if (isObjectEmpty(editedUser)) {
        setError('root', { message: "Oops! You haven't made any change." });
        return;
      }
      const response = await axiosPrivate.patch(
        '/api/v1/users',
        JSON.stringify(editedUser),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      if (response?.data?.status === 'success') {
        setSuccess('Changes saved');
        const updatedUser = response?.data?.data?.user;
        setUser(updatedUser);
        setAuth(prev => {
          return { ...prev, user: updatedUser };
        });
        //display success message that disappears in 3 secs
        setTimeout(() => {
          setSuccess('');
        }, 3000);
      } else {
        setError('root', { message: response?.data?.message });
      }

      //console.log('New USER data: ', updatedUser);
    } catch (error) {
      setError('root', {
        message: error.message,
      });
    }
  }

  // automatically focus on name field after editing is activated
  React.useEffect(() => {
    setFocus('name');
  }, [isEditing, setFocus]);

  React.useEffect(() => {
    setEditing(false);
  }, [success]);

  async function handleImageChange(e) {
    e.preventDefault();
    setPhoto(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  }

  async function uploadImage() {
    let imgUrl;
    try {
      // get cloudinary from backend
      const configRes = await axiosPrivate.get('/api/v1/auth/config');
      const config = configRes?.data?.data;
      // ensure photo is selected and photo has been changed
      if (photo && photo !== user.photo) {
        const formData = new FormData();
        formData.append('file', photo);
        formData.append('cloud_name', config.cloudinaryName);
        formData.append('upload_preset', config.cloudinaryPreset);
        const url = `https://api.cloudinary.com/v1_1/${config.cloudinaryName}/image/upload`;
        const response = await axios.post(url, formData);
        imgUrl = response?.data?.url.toString();
        //update user form data photo
        //console.log('IMG URL Res: ', response?.data);

        //console.log('Cloud IMGURL:', imgUrl);
        setImageUrl(imgUrl);
        setValue('photo', imgUrl);
        setPhoto(null);
      }
    } catch (error) {
      setError('root', {
        message: 'Error submitting your details. Please try again later',
      });
      //console.log('Error: uploading image; ', error.message);
    }
    return imgUrl ?? user.photo;
  }

  // console.log('Photo: ', photo);
  function onDeleteImage() {
    setPhoto(null);
    setImageUrl('');
    setValue('photo', '');
  }

  return (
    <div className='w-full p-5'>
      <form
        className='grid grid-cols-1 gap-4 md:grid-cols-2  lg:grid-cols-3 bg-white rounded-lg p-4 text-gray-500'
        onSubmit={handleSubmit(updateUser)}
      >
        <div className='md:col-span-2 lg:col-span-3 flex flex-col space-y-2 text-gray-500 '>
          <p>Photo </p>

          <div className='mb-4 flex items-center gap-4'>
            {imageUrl && (
              <div className='relative w-[200px] max-h-[200px] h-full rounded-md overflow-hidden'>
                <div className='z-10 absolute top-2 right-2'>
                  <button
                    type='button'
                    disabled={!isEditing}
                    onClick={() => onDeleteImage()}
                    className='bg-red-500 p-2 rounded-md text-white'
                    size='sm'
                  >
                    <Trash className='h-4 w-4' />
                  </button>
                </div>
                <div>
                  <img
                    className='object-cover h-full'
                    alt='Profile photo'
                    src={imageUrl}
                  />
                </div>
              </div>
            )}
          </div>
          {!imageUrl && (
            <input
              disabled={!isEditing || imageUrl}
              className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 w-full rounded-lg'
              type='file'
              accept='image/*'
              placeholder='Upload a photo'
              onChange={handleImageChange}
              //{...register('photo', imageUrl)}
            />
          )}
          {errors.photo && <ErrorMessage message={errors.photo.message} />}
        </div>
        <div className='flex flex-col space-y-2'>
          <p>Name </p>
          <input
            disabled={!isEditing}
            className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
            type='text'
            placeholder='Enter your name'
            {...register('name')}
          />
          {errors.name && <ErrorMessage message={errors.name.message} />}
        </div>
        <div className='flex flex-col space-y-2'>
          <p>Email </p>
          <input
            disabled={!isEditing}
            className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
            type='email'
            placeholder='i.e pretty@gmail.com'
            {...register('email')}
          />
          {errors.email && <ErrorMessage message={errors.email.message} />}
        </div>
        <div className='flex flex-col space-y-2 '>
          <p>Phone number </p>
          <input
            disabled={!isEditing}
            className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
            type='number'
            placeholder='i.e 07/01********'
            {...register('phone')}
          />
          {errors.phone && <ErrorMessage message={errors.phone.message} />}
        </div>
        <div className='flex flex-col space-y-2  items-start justify-center'>
          <div className='flex items-center'>
            <input
              disabled={!isEditing}
              type='checkbox'
              id='hs-small-switch'
              {...register('is_mama_fua')}
              className='relative w-11 h-6 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-[#8f43ec] disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-[#8f43ec] checked:border-[#8f43ec] focus:checked:border-[#8f43ec] before:inline-block before:size-5 before:bg-white checked:before:bg-[#ffe6f1] before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 '
            />

            <p className='text-md text-gray-500 ms-3 dark:text-gray-400'>
              Are you mama fua?
            </p>
          </div>
          {errors.is_mama_fua && (
            <ErrorMessage message={errors.is_mama_fua.message} />
          )}
        </div>
        <div className='flex flex-col space-y-2 '>
          <p>Age </p>
          <input
            disabled={!isEditing}
            className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
            type='number'
            placeholder='i.e. 20'
            {...register('age')}
          />
          {errors.age && <ErrorMessage message={errors.age.message} />}
        </div>
        <div className='flex flex-col space-y-2 '>
          <p>Gender </p>
          <select
            disabled={!isEditing}
            {...register('gender')}
            className='py-3 px-4 border-gray-200 rounded-lg  focus:border-[#8f43ec] focus:ring-[#8f43ec]'
          >
            {genders.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          {errors.gender && <ErrorMessage message={errors.gender.message} />}
        </div>
        <div className='flex flex-col space-y-2 '>
          <p>Orientation </p>
          <select
            disabled={!isEditing}
            {...register('orientation')}
            className='py-3 px-4 border-gray-200 rounded-lg  focus:border-[#8f43ec] focus:ring-[#8f43ec]'
          >
            {orientations.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          {errors.orientation && (
            <ErrorMessage message={errors.orientation.message} />
          )}
        </div>
        <div className='flex flex-col space-y-2 '>
          <p>Location </p>
          <input
            disabled={!isEditing}
            className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
            type='text'
            placeholder='i.e. Nairobi CBD'
            {...register('location')}
          />
          {errors.location && (
            <ErrorMessage message={errors.location.message} />
          )}
        </div>
        <div className='flex flex-col space-y-2 '>
          <p>Description </p>
          <input
            disabled={!isEditing}
            className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
            type='text'
            placeholder='More information about your services...'
            {...register('description')}
          />
          {errors.description && (
            <ErrorMessage message={errors.description.message} />
          )}
        </div>
        <div className='flex flex-col space-y-2 lg:col-span-2 '>
          <p>Services (seperated by comma) </p>
          {/* <div className='flex flex-wrap gap-2 border border-gray-300 rounded-lg p-3'>
            {services.map(service => (
              <div
                key={service}
                className='bg-gray-200  text-sm px-2 rounded-lg cursor-pointer flex items-center'
                onClick={() => handleRemoveTag(service)}
              >
                {service}
                <X className='h-3 w-3 ml-2' />
              </div>
            ))}

            <input
              type='text'
              placeholder='Type and press Enter'
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              {...register('services')}
              className='text-md border-none rounded px-2 py-1 focus:outline-none focus:border-[#8f43ec] border focus:ring-0 '
            />
          </div> */}

          <input
            disabled={!isEditing}
            type='text'
            placeholder='Type and press Enter'
            {...register('services')}
            className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
          />
          {errors.services && (
            <ErrorMessage message={errors.services.message} />
          )}
        </div>

        <div className='md:col-span-3'>
          {isEditing && (
            <button
              disabled={isSubmitting}
              className='bg-[#ff3190] px-5 py-2 text-white rounded-lg hover:text-[#ff3190] hover:bg-[#ffe6f1] hover:cursor-pointer mx-2'
              type='submit'
            >
              {isSubmitting ? 'Saving...' : 'Save changes'}
            </button>
          )}
          {!isEditing && (
            <button
              className='bg-[#8f43ec] px-5 py-2 text-white rounded-lg hover:text-[#8f43ec] hover:bg-[#e3d1fa] border border-[#8f43ec] hover:cursor-pointer mx-2'
              onClick={() => setEditing(true)}
            >
              Edit Profile
            </button>
          )}
          {isEditing && (
            <button
              className='bg-[#ffffff] px-5 py-2 text-gray-500 rounded-lg  border border-gray-300 hover:cursor-pointer mx-2'
              onClick={() => {
                setEditing(false);
                //on cancel reset the form to retain default values
                reset();
                // restore the photo too
                setImageUrl(stateUser.photo);
              }}
            >
              Cancel
            </button>
          )}
          {errors.root && <ErrorMessage message={errors.root.message} />}
          {success && (
            <p className='p-2 my-4 bg-green-100 text-green-500 flex gap-3 rounded-lg'>
              <Check />
              {success}
            </p>
          )}
        </div>
      </form>

      <div className='flex gap-5 mt-4 other'>
        <div className='changePassword'>
          <EditPassword />
        </div>
        <div className='support'>
          <Link to='/contact'> Contact for Help</Link>
        </div>
      </div>
    </div>
  );
}

function EditPassword() {
  const axiosPrivate = useAxiosPrivate();
  const [openModal, setOpenModal] = useState(false);
  const formSchema = z.object({
    current_password: z
      .string()
      .min(8, { message: 'Password must be at least 8 characters' }),
    new_password: z
      .string()
      .min(8, { message: 'Password must be at least 8 characters' }),
  });

  const defaultValues = { current_password: '', new_password: '' };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: zodResolver(formSchema), defaultValues });

  async function updatePassowrd(data) {
    try {
      const response = await axiosPrivate.post(
        '/api/v1/auth/update-password',
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      const res = response?.data;

      if (res.status !== 'success') {
        setError('root', {
          message: res.message,
        });
        return;
      }

      // reaching here means the change was successful
      setTimeout(() => {
        setOpenModal(false);
        setValue('current_password', '');
        setValue('new_password', '');
      }, 2000);
    } catch (error) {
      setError('root', {
        message: error.message,
      });
    }
  }

  function onCloseModal() {
    setOpenModal(false);
    setValue('current_password', '');
    setValue('new_password', '');
    setError('root', '');
  }

  return (
    <>
      <span
        className='w-full h-full items-center flex'
        onClick={() => setOpenModal(true)}
      >
        Change password
      </span>
      <Modal show={openModal} size='md' onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <form className='space-y-6' onSubmit={handleSubmit(updatePassowrd)}>
            <h3 className='text-xl font-medium text-gray-900 dark:text-white'>
              Set up a new password
            </h3>
            <div className='flex flex-col space-y-2 '>
              <p>Current password </p>
              <input
                className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
                type='password'
                placeholder='Old password...'
                {...register('current_password')}
              />
              {errors.current_password && (
                <ErrorMessage message={errors.current_password.message} />
              )}
            </div>
            <div className='flex flex-col space-y-2 '>
              <p>New password </p>
              <input
                className='border-gray-200 focus:border-[#8f43ec] border focus:ring-0 px-2 py-2 w-full rounded-lg'
                type='password'
                placeholder='New password...'
                {...register('new_password')}
              />
              {errors.new_password && (
                <ErrorMessage message={errors.new_password.message} />
              )}
            </div>
            <div className='w-full'>
              <button
                className='bg-[#ff3190] border border:transparent rounded-lg text-white hover:text-[#ff3190] hover:border-[#ff3190] hover:bg-white px-4 py-2'
                disabled={isSubmitting}
                type='submit'
              >
                {isSubmitting ? 'Applying changes...' : 'Update password'}
              </button>
              {errors.root && <ErrorMessage message={errors.root.message} />}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

// get update fields that makes new object
function compareObjects(obj1, obj2) {
  const diff = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        diff[key] = obj2[key];
      }
    }
  }

  return diff;
}

// function to check if object is empty
function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export default Profile;
